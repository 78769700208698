<template>
  <section>
    <section class="form-container">
      <section class="form-item">
        <p class="title m-t-10">离场人员信息</p>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="columns"
            :data-source="selPerList"
            :pagination="false"
          >
            <a slot="action" slot-scope="list">
              <a-popconfirm
                title="确定删除?"
                @confirm="deletePersonnel(list.key)"
                >删除</a-popconfirm
              >
            </a>
          </a-table>
        </section>
      </section>
      <section class="form-item">
        <p class="title m-t-10">退场信息</p>
        <a-form class="m-t-16" layout="inline" :form="form">
          <a-form-item label="退场时间">
            <!-- :disabledDate="disabledEndDate" -->
            <a-date-picker
              ref="outDate"
              disabled 
              v-decorator="[
                'outDate',
                { rules: [{ required: true, message: '请选择退场时间' }] },
              ]"
            />
          </a-form-item>
        </a-form>
        <section class="form-item m-t-10">
          <div
            class="form-btn-group"
            style="text-align: center; padding: 18px 0"
          >
            <a-button @click="submit"><a-icon type="save" />保存</a-button>
            <a-button @click="$router.back()">取消</a-button>
          </div>
        </section>
      </section>
    </section>
  </section>
</template>


<script>
const columns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "单位名称",
    dataIndex: "companyName",
    align: "center",
    key: "companyName",
  },
  {
    title: "职位/工种",
    dataIndex: "jobName",
    align: "center",
    key: "jobName",
  },
  {
    title: "班组名称",
    dataIndex: "teamName",
    align: "center",
    key: "teamName",
  },
  {
    title: "姓名",
    dataIndex: "personnelName",
    align: "center",
    key: "personnelName",
  },
  {
    title: "联系方式",
    dataIndex: "phoneNumber",
    align: "center",
    key: "fileNaphoneNumberme",
  },
  { title: "登记时间", dataIndex: "inDate", align: "center", key: "inDate" },
  {
    title: "操作",
    align: "center",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
// import previewImage from '@/components/previewImage'
// import upload from "@/components/upload";
import moment from "moment";
export default {
  data() {
    return {
      columns,
      selPerList: [],
    };
  },
  // components:{upload,previewImage},
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  // computed: {
  //   picServer() {
  //     return this.$store.state.picServer;
  //   },
  // },
  mounted() {
    this.form.setFieldsValue({
      outDate: moment(),
    });
  },
  created() {
    // this.queryPersonnelDetails()
    this.selPerList = JSON.parse(localStorage.getItem("selPerInformation"));
    this.selPerList.forEach((item, ind) => {
      item.key = ind + 1;
      item.inDate =
        item.inDate != "" ? moment(item.inDate).format("YYYY-MM-DD") : "-";
      item.companyName = item.companyName ? item.companyName : "-";
      item.teamName = item.teamName ? item.teamName : "-";
      item.jobName = item.jobName ? item.jobName : "-";
    });
    console.log("批量退场人员信息=>", this.selPerList);
  },
  methods: {
    // disabledEndDate(endValue) {
    //   const inDate = moment(this.personnelInfo.inDate);
    //   if (!inDate || !endValue) return false;
    //   return inDate.valueOf() >= endValue.valueOf();
    // },
    //删除人员
    deletePersonnel(key) {
      if (this.selPerList.length <= 1) {
        this.$message.error("至少保留一条人员信息，否则无法退场");
      } else {
        const dataSource = [...this.selPerList];
        this.selPerList = dataSource.filter((item) => item.key !== key);
        this.selPerList.forEach((item, ind) => {
          item.key = ind + 1;
          item.inDate = moment(item.inDate).format("YYYY-MM-DD");
        });
        console.log("删除后退场人员信息=>", this.selPerList);
      }
    },
    batchUnBindFaceDevice(info) {
      this.$api.batchUnBindFaceDevice(info).then(() => {});
    },
    //提交表单
    submit() {
      this.form.validateFields((err, formData) => {
        if (!err) {
          console.log(formData);
          // let ids = "";
          let personnelArr = [];
          this.selPerList.forEach((item) => {
            // ids += item.id + ",";
            personnelArr.push(item.id);
          });
          // ids=ids.slice(0,ids.length-1)
          // console.log("选中退场的人员id", ids);
          formData.outDate = formData.outDate
            ? formData.outDate.format("YYYY-MM-DD")
            : null;
          let params = {
            ids: personnelArr,
            outDate: formData.outDate,
          };
          let perInfo = {
            ids: personnelArr,
          };
      
          this.$api.personnelBatchExit(params).then((res) => {
            if (res.code === 200) {
              this.batchUnBindFaceDevice(perInfo);
              this.$router.back();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>